import { useEffect, useState } from "react";
import { AiOutlineLoading } from "react-icons/ai";
import queryString from "query-string";
import dayjs from "dayjs";
import api from "./api";
import Layout from "./Layout";

function App() {
  const [cancel, setCancel] = useState(false);
  const [loading, setLoading] = useState(true);
  const [clinicName, setClinicName] = useState("");
  const [dateTime, setDateTime] = useState("");
  const [submissionID, setSubmissionID] = useState("");
  const [cancelling, setCancelling] = useState(false);
  const { appID } = queryString.parse(window.location.search);

  // const isOnTimeToCancel =
  //   dayjs().diff(dayjs(dateTime).subtract(15, "minute")) < 0;

  const isOnTimeToCancel =
    dayjs().diff(dayjs(dateTime).subtract(2, "minute")) < 60000;

  console.log(isOnTimeToCancel, dayjs().diff(dayjs(dateTime)));

  const dayjsFormatDateTime = dayjs(dateTime).format("ddd, D MMM YYYY h:mma");

  const handleAcceptButton = async () => {
    setCancelling(true);
    try {
      await api.cancelAppointment({
        APPID: appID,
        SubmissionID: submissionID,
        bodyType: "cancel_confirmation",
      });
      setCancel(true);
      setCancelling(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    api
      .getAppointmentData({
        appointmentID: appID,
      })
      .then((res) => {
        const formatClinicName = res.data.clinicName
          .split("-")
          .map((val) => val.trim())
          .join(" - ");

        const formatDateTime = res.data.datetime.slice(
          0,
          res.data.datetime.length - 7
        );

        setClinicName(formatClinicName);
        setDateTime(formatDateTime);
        setSubmissionID(res.data.submissionID);
        setLoading(false);
      })
      .catch((err) => console.error(err));
  }, [appID]);

  if (loading) {
    return (
      <div className="h-screen flex items-center justify-center ">
        <AiOutlineLoading className="animate-spin text-teal-peg w-24 h-24"></AiOutlineLoading>
      </div>
    );
  }

  if (cancel) {
    return (
      <Layout>
        <div className="text-center text-lg px-2">
          <p className="mb-2">
            You have cancelled the appointment at{" "}
            <span className="font-bold text-teal-peg">{clinicName}</span> on{" "}
            <span className="font-bold">{dateTime}</span>, sucessfully.
          </p>
        </div>
      </Layout>
    );
  }

  if (!isOnTimeToCancel) {
    return (
      <Layout>
        <div className="text-center text-lg px-2 mb-2">
          <p>You can not cancel this appointment anymore.</p>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="text-center text-lg px-2">
        <p className="mb-2">
          You have an appointment at{" "}
          <span className="font-bold text-teal-peg">{clinicName}</span>
          <br />
          on <span className="font-bold">{dayjsFormatDateTime}.</span>
        </p>
        <p className="font-bold">Are you sure you want to cancel?</p>
      </div>
      <nav className="flex w-full p-2">
        <ul className="flex gap-2 w-full  justify-between items center">
          <li className="w-full">
            <button
              className="btn bg-teal-peg hover:bg-teal-peg-hover w-full disabled:cursor-not-allowed disabled:bg-gray-400 disabled:animate-pulse"
              disabled={cancelling}
              onClick={handleAcceptButton}
            >
              {cancelling ? "Cancelling..." : "Yes, cancel"}
            </button>
          </li>
        </ul>
      </nav>
    </Layout>
  );
}

export default App;
